<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("ROLES.EDIT_ROLE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <role-form
              :loading="loading"
              :roleData="role"
              :formErrors="formErrors"
              @roleSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import RoleForm from "./partials/RoleForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRole from "./defaultRole";

export default {
  layout: "DashboardLayout",

  components: { RoleForm },
  mixins: [alertLeave],

  data() {
    return {
      role: cloneDeep(defaultRole),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("roles/get", id);
        this.role = this.$store.getters["roles/role"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Roles" });
    },

    viewRole() {
      this.alertLeave = false;
      this.$router.push({ name: "View Role", params: { id: this.role.id } });
    },

    async handleSubmit(role) {
      this.loading = true;

      const roleData = cloneDeep(role);
      if (!roleData.organization.id) {
        delete roleData.organization;
      }
      if (roleData.reseller) {
        if (!roleData.reseller.id) {
          delete roleData.reseller;
        }
      }

      try {
        await this.$store.dispatch("roles/update", roleData);
        this.$notify({
          type: "success",
          message: this.$t("ROLES.ROLES_UPDATED"),
        });
        this.viewRole();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
